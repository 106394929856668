<template>
  <div class="container">
    <div
      id="bg"
      :style="`background-image: ${backgroundImage}`"
      class="container mx-5 my-7"
    >
      <h1>Welcome</h1>
      <p>The Home of African Content</p>
      <div class="btn btn-primary" @click="downloadApp">Download Diapps</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    downloadApp() {},
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg6.jpg";
    },
  },
};
</script>
<style scoped>
/* #bg {
  height: 100vh;
  background-image: url("../media/bg/bg6.jpg");
} */
</style>
